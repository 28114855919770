body {
  min-height: 100vh;
  font-family: Roboto, Arial;
  color: #adafb6;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(249, 251, 255, 0.6);
}
:root {
  --lightpurple: #b59ff2;
  --purple: #906eeb;
  --darkpurple: #673ae5;
  --pink: #d496a7;
  --lightpink: #f6c0d0;
  --darkpink: #8e7c93;
  --green: #17bebb;
  --darkgreen: #037171;
  --blue: #6cd4ff;
}
.boxes {
  height: 32px;
  width: 32px;
  position: relative;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  margin-top: 32px;
  -webkit-transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}
.boxes .box {
  width: 32px;
  height: 32px;
  top: 0px;
  left: 0;
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.boxes .box:nth-child(1) {
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-animation: box1 1s linear infinite;
  animation: box1 1s linear infinite;
}
.boxes .box:nth-child(2) {
  -webkit-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-animation: box2 1s linear infinite;
  animation: box2 1s linear infinite;
}
.boxes .box:nth-child(3) {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
  -webkit-animation: box3 1s linear infinite;
  animation: box3 1s linear infinite;
}
.boxes .box:nth-child(4) {
  -webkit-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-animation: box4 1s linear infinite;
  animation: box4 1s linear infinite;
}

.boxes .box > div {
  background: #8d67f5;
  --translateZ: 15.5px;
  --rotateY: 0deg;
  --rotateX: 0deg;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #5c8df6;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  -webkit-transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
    translateZ(var(--translateZ));
  transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
    translateZ(var(--translateZ));
}

.boxes .box > div:nth-child(1) {
  top: 0;
  left: 0;
  background: #8d67f5;
}
.boxes .box > div:nth-child(2) {
  background: #673ae5;
  right: 0;
  --rotateY: 90deg;
}
.boxes .box > div:nth-child(3) {
  background: #825beb;
  --rotateX: -90deg;
}
.boxes .box > div:nth-child(4) {
  background: #dbe3f4;
  top: 0;
  left: 0;
  --translateZ: -90px;
}

@keyframes box1 {
  0%,
  50% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}

@keyframes box3 {
  0%,
  50% {
    transform: translate(100%, 100%);
  }
  100% {
    transform: translate(0, 100%);
  }
}

@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }
  50% {
    transform: translate(200%, 100%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}

#LoadingBar1 {
  border-radius: 35px;
  height: 40px;
  width: 280px;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.purplebox {
  background-color: var(--lightpurple);
  margin-left: 1%;
  width: 13%;
  height: 80%;
  visibility: hidden;
}

#box1,
#box2,
#box3,
#box4,
#box5,
#box6,
#box7 {
  animation-name: box;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

#box1 {
  margin-left: 0%;
  border-radius: 35px 0px 0px 35px;
}

#box2,
#O {
  animation-delay: 0.25s;
}

#box3,
#A {
  animation-delay: 0.5s;
}

#box4,
#D {
  animation-delay: 0.75s;
}

#box5,
#I {
  animation-delay: 1s;
}

#box6,
#N {
  animation-delay: 1.25s;
}

#box7,
#G {
  animation-delay: 1.5s;
  border-radius: 0px 35px 35px 0px;
}

@keyframes box {
  0% {
    visibility: hidden;
    background-color: var(--lightpurple);
  }
  20% {
    visibility: hidden;
  }
  33% {
    visibility: visible;
  }
  50% {
    background-color: var(--purple);
  }
  100% {
    background-color: var(--darkpurple);
  }
}

.circle-loader {
  border: 2px solid rgba(0, 0, 0, 0);
  border-left-color: #5f2ee5;
  border-right-color: #5f2ee5;

  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border: none;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
}
.checkmark-after-draw {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark-after {
  opacity: 1;
  height: 0.8em;
  width: 0.4em;
  transform-origin: left top;
  border-right: 2px solid #5cb85c;
  border-top: 2px solid #5cb85c;
  content: "";
  left: 0.2em;
  top: 0.8em;
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.4em;
    opacity: 1;
  }
  40% {
    height: 0.8em;
    width: 0.4em;
    opacity: 1;
  }
  100% {
    height: 0.8em;
    width: 0.4em;
    opacity: 1;
  }
}
