@tailwind base;
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: "black" !important;
}

.five {
  -webkit-animation-delay: 4.5s;
  -moz-animation-delay: 4.5s;
  animation-delay: 4.5s;
}

/*=== FADE IN  ===*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@font-face {
  font-family: "Euclid Circular B";
  src: local("Euclid Circular B"),
    url("./assets/fonts/Euclid\ Circular\ B\ SemiBold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Euclid Circular B";
  src: local("Euclid Circular B"),
    url("./assets/fonts/Euclid\ Circular\ B\ Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SpaceMono";
  src: local("SpaceMono"),
    url("./assets/fonts/SpaceMono-Regular.ttf") format("truetype");
  font-weight: normal;
}
